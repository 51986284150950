@use 'sass:map';
@use '@angular/material' as mat;

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$mat-green: (
        50: #e8f5e9,
        100: #c8e6c9,
        200: #a5d6a7,
        300: #81c784,
        400: #66bb6a,
        500: #4caf50,
        600: #43a047,
        700: #388e3c,
        800: #2e7d32,
        900: #1b5e20,
        A100: #b9f6ca,
        A200: #69f0ae,
        A400: #00e676,
        A700: #00c853,
        contrast: (
                50: $black-87-opacity,
                100: $black-87-opacity,
                200: $black-87-opacity,
                300: $black-87-opacity,
                400: $black-87-opacity,
                500: white,
                600: white,
                700: white,
                800: $white-87-opacity,
                900: $white-87-opacity,
                A100: $black-87-opacity,
                A200: $black-87-opacity,
                A400: $black-87-opacity,
                A700: $black-87-opacity,
        ),
);
$mat-deep-orange: (
        50: #fbe9e7,
        100: #ffccbc,
        200: #ffab91,
        300: #ff8a65,
        400: #ff7043,
        500: #ff5722,
        600: #f4511e,
        700: #e64a19,
        800: #d84315,
        900: #bf360c,
        A100: #ff9e80,
        A200: #ff6e40,
        A400: #ff3d00,
        A700: #dd2c00,
        contrast: (
                50: $black-87-opacity,
                100: $black-87-opacity,
                200: $black-87-opacity,
                300: $black-87-opacity,
                400: $black-87-opacity,
                500: white,
                600: white,
                700: white,
                800: white,
                900: white,
                A100: $black-87-opacity,
                A200: $black-87-opacity,
                A400: white,
                A700: white,
        ),
);

$mat-light-blue: (
        50: #e1f5fe,
        100: #b3e5fc,
        200: #81d4fa,
        300: #4fc3f7,
        400: #29b6f6,
        500: #03a9f4,
        600: #039be5,
        700: #0288d1,
        800: #0277bd,
        900: #01579b,
        A100: #80d8ff,
        A200: #40c4ff,
        A400: #00b0ff,
        A700: #0091ea,
        contrast: (
                50: $black-87-opacity,
                100: $black-87-opacity,
                200: $black-87-opacity,
                300: $black-87-opacity,
                400: $black-87-opacity,
                500: white,
                600: white,
                700: white,
                800: white,
                900: $white-87-opacity,
                A100: $black-87-opacity,
                A200: $black-87-opacity,
                A400: $black-87-opacity,
                A700: white,
        ),
);
$red-palette: (
        50: #ffebee,
        100: #ffcdd2,
        200: #ef9a9a,
        300: #e57373,
        400: #ef5350,
        500: #f44336,
        600: #e53935,
        700: #d32f2f,
        800: #c62828,
        900: #b71c1c,
        A100: #ff8a80,
        A200: #ff5252,
        A400: #ff1744,
        A700: #d50000,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $dark-primary-text,
                400: $dark-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

$primary: mat.define-palette($mat-green, 500);
$accent: mat.define-palette($mat-deep-orange, 500);
$theme: mat.define-light-theme($primary, $accent);

//Set EU theme
$eu-primary: mat.define-palette($mat-light-blue, 500);
$eu-accent: mat.define-palette($red-palette, 500);
$eu-theme: mat.define-light-theme($eu-primary, $eu-accent);

$custom-colors: (
        custom-color-a: mat.get-color-from-palette($mat-green, 700),
        custom-color-b: mat.get-color-from-palette($mat-deep-orange, 400),
);

$theme: map.merge($theme, (custom-colors: $custom-colors));



// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes($theme);


$primary-color: mat.get-color-from-palette($primary, 500);
$primary-color-dark: mat.get-color-from-palette($primary, 700);
$primary-color-light: mat.get-color-from-palette($primary, 100);
$primary-color-text: #FFFFFF;
$accent-color: mat.get-color-from-palette($accent, 500);
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #BDBDBD;
$grey-extra-light: #E5E5E5;
$grey-extra-light-2: #f5f5f5;
$grey-extra-light-3: #ebebeb;
$secondary-text-color-light: #a3a3a3;


:root {
  --primary-color: #{$primary-color};
  --primary-color-dark: #{$primary-color-dark};
  --primary-color-light: #{$primary-color-light};
  --primary-color-text: #{$primary-color-text};
  --accent-color: #{$accent-color};
  --primary-text-color: #{$primary-text-color};
  --secondary-text-color: #{$secondary-text-color};
  --divider-color: #{$divider-color};
  --grey-extra-light: #{$grey-extra-light};
  --grey-extra-light-2: #{$grey-extra-light-2};
  --grey-extra-light-3: #{$grey-extra-light-3};
  --secondary-text-color-light: #{$secondary-text-color-light};
}

//Set EU theme
.eu-theme {
  @include mat.all-component-themes($eu-theme);

  $eu-primary-color: mat.get-color-from-palette($eu-primary, 500);
  $eu-primary-color-dark: mat.get-color-from-palette($eu-primary, 700);
  $eu-primary-color-light: mat.get-color-from-palette($eu-primary, 100);
  $eu-primary-color-text: #FFFFFF;
  $eu-accent-color: mat.get-color-from-palette($eu-accent, 500);
  $eu-primary-text-color: #212121;
  $eu-secondary-text-color: #757575;
  $eu-divider-color: #BDBDBD;
  $eu-grey-extra-light: #E5E5E5;
  $eu-grey-extra-light-2: #f5f5f5;
  $eu-grey-extra-light-3: #ebebeb;
  $eu-secondary-text-color-light: #a3a3a3;

  --primary-color: #{$eu-primary-color};
  --primary-color-dark: #{$eu-primary-color-dark};
  --primary-color-light: #{$eu-primary-color-light};
  --primary-color-text: #{$eu-primary-color-text};
  --accent-color: #{$eu-accent-color};
  --primary-text-color: #{$eu-primary-text-color};
  --secondary-text-color: #{$eu-secondary-text-color};
  --divider-color: #{$eu-divider-color};
  --grey-extra-light: #{$eu-grey-extra-light};
  --grey-extra-light-2: #{$eu-grey-extra-light-2};
  --grey-extra-light-3: #{$eu-grey-extra-light-3};
  --secondary-text-color-light: #{$eu-secondary-text-color-light};
  --dsfg: rgba(220, 220, 220, 0.5)
  
}


