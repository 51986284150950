@keyframes loading
  0%
    width: 0
    left: 0
    right: auto
  49%
    width: 100%
    left: 0
    right: auto
  50%
    width: 100%
    left: auto
    right: 0
  100%
    width: 0
    left: auto
    right: 0

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes fadeOut
  from
    opacity: 1
  to
    opacity: 0

@keyframes flip
  0%
    transform: perspective(900px) rotateY(0)
    animation-timing-function: ease-out

  40%
    transform: perspective(900px) translateZ(150px) rotateY(170deg)
    animation-timing-function: ease-out

  50%
    transform: perspective(900px) translateZ(150px) rotateY(190deg) scale(1)
    animation-timing-function: ease-in

  80%
    transform: perspective(900px) rotateY(360deg) scale(.95)
    animation-timing-function: ease-in

  100%
    transform: perspective(900px) scale(1)
    animation-timing-function: ease-in
