@import "utils"
@import "animations"
@import "palette"

// global reusable elements
button:focus
  outline: none !important

// input spinner
mat-spinner.input-spinner
  position: absolute
  right: 1px
  height: 15px !important
  width: 15px !important
  top: calc(50% - 7px)
  .mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic
    height: 15px
    width: 15px

// horizontal line
hr.hr
  background-color: $divider-color
  height: 1px
  border: 0
  margin: 0

// stepper
// stepper header
td-steps.create-event-steps .td-steps-header
  box-shadow: none !important
// stepper content
td-steps.create-event-steps .td-step-content
  margin: 0
// stepper cards
td-steps.create-event-steps .td-step-content .mat-card
  margin: 0

td-steps.create-event-steps .td-step-content .mat-card-title
  color: rgba(0, 0, 0, .54)
  font-size: 18px
  background-color: rgba(0, 0, 0, .03)
  margin-bottom: 0
  padding-bottom: 16px

// custom card
.card_custom
  border-top: 1px solid var(--divider-color)
  border-right: 1px solid var(--divider-color)
  border-left: 1px solid var(--divider-color)

  mat-card-content.mat-card-content
    @media (min-width: 600px)
      padding: 2em

// custom table
.table
  &__head
    color: var(--secondary-text-color)
    background-color: rgba(0, 0, 0, 0.03)

.button_icon
  display: flex
  min-width: 0
  justify-content: center
  align-items: center

// custom textarea
.textarea_custom
  .mat-input-wrapper
    width: 100%

// custom input
mat-input-container.input_no-label
  .mat-input-infix
    border-top: 0 !important

mat-card-title .controls
  width: 120px !important
  font-size: 1rem !important
  margin-left: auto !important

mat-sidenav .mat-list-item
  transition: all .3s ease-in-out

  &:hover
    color: $primary-color !important
    text-decoration: none !important

  &.active-item
    background-color: $primary-color-light !important

.btn
  transition: all .3s ease-in-out
  opacity: 0.8

  &:hover
    opacity: 1

  &_primary
    background-color: $primary-color

  &_primary-light
    background-color: $primary-color-light

  &_warn
    background-color: $warning

  &_rounded
    border-radius: 50% !important

td-search-box.td-search-box_custom
  height: 72px

  .mat-input-placeholder
    color: white !important

mat-tab-group.mat-tab-group_custom
  .mat-tab-label
    text-transform: uppercase
    color: white

  .mat-chip
    color: white
    background: $primary-color

  .mat-ink-bar
    background-color: $accent-color !important

.td-layout-nav-wrapper > mat-toolbar
  z-index: 2 !important

.td-data-table-scrollable
  overflow: hidden !important

.td-data-table-row
  cursor: pointer

.secondary-text
  color: $secondary-text-color

.divider
  display: block
  height: 1px
  background-color: $divider-color
  opacity: .5

mat-toolbar.progress
  position: relative !important

  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background-color: rgba(255, 255, 255, .2)
    animation: loading 3s infinite

.small-green-text
  cursor: pointer
  font-size: 13px
  color: $primary-color


a
  color: $primary-color
  text-decoration: none
  background-color: transparent


mat-option.mat-mdc-option .mdc-list-item__primary-text
  white-space: nowrap

.material-icons, .material-icons.mat-icon
  font-family: 'Material Symbols Outlined', serif
  line-height: 1
  font-size: 24px
  height: 1em
  width: 1em

mat-bottom-sheet-container.mat-bottom-sheet-container
  width: 100%
  min-width: unset

.mat-mdc-dialog-title.mdc-dialog__title
  margin-bottom: 20px
  font-size: 20px
  line-height: 32px
  font-weight: 500


button.outline-button
  margin-bottom: 24px
  color: $primary-color
  border-radius: 24px
  border: 1px solid $primary-color
  &:not(:disabled)
    color: $primary-color
    border: 1px solid $primary-color

.mailing-bottom-sheet-container
  mat-bottom-sheet-container
    max-height: 90vh

.mailing-addressees-dialog.cdk-overlay-pane.mat-mdc-dialog-panel
  max-width: 800px
  width: 800px
