/* Palette generated by Material Palette - materialpalette.com/green/red */

$primary-color-dark: #388E3C
$primary-color: #4CAF50
$primary-color-light: #C8E6C9
$primary-color-text: #FFFFFF
$accent-color: #FF5722
$primary-text-color: #212121
$secondary-text-color: #757575
$divider-color: #BDBDBD

// custom colors
$grey-extra-light: #E5E5E5
$grey-extra-light-2: #f5f5f5
$grey-extra-light-3: #ebebeb
$secondary-text-color-light: #a3a3a3

$warning: #ffc107

