@use 'sass:map'
@use '@angular/material' as mat

@import 'seatyjs/dist/seatyjs.bundle.css'
@import '@v-ticket-tools/seatyjs-v2/dist/seatyjs-v2.bundle.css'
@import 'font-awesome/css/font-awesome.css'

@import 'assets/style/palette'
@import 'assets/style/utils'
@import 'assets/style/elements'
@import 'assets/style/themes'
@import 'assets/style/material_styles'
@import 'quill/dist/quill.snow.css'
@import 'quill/dist/quill.core.css'

@media print
  *
    box-shadow: none !important
  .wrapper-scroll
    position: unset

*
  box-sizing: border-box
  margin: 0
  padding: 0
  letter-spacing: 0


html, body
  height: -webkit-fill-available

body
  position: relative
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #212529
  text-align: left
  background-color: #fff

.block-no-scroll
  height: calc(100% - 64px)
  overflow: hidden

.wrapper-scroll
  overflow-x: hidden
  height: 100%

html.cdk-global-scrollblock
  position: initial !important

.blur
  filter: blur(10px) opacity(70%) grayscale(100%)
  transition: 1s filter

.mat-drawer-container
  background-color: #e0e0e0

.offline-alert
  min-height: auto !important
  border-radius: 4px !important
  color: rgba(255, 255, 255, .7) !important
  background: #323232 !important
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12) !important

  .sn-title
    font-size: 14px !important
    padding: 0 !important

  .icon
    display: none !important

.invites-create-done
  min-height: auto !important
  border-radius: 4px !important
  color: rgba(255, 255, 255, .7) !important
  background: #323232 !important
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12) !important

  .sn-title
    font-size: 14px !important
    padding: 0 !important
    line-height: 25px !important

  .icon
    display: none !important

  .sn-progress-loader
    display: none !important

  @media (max-width: 540px)
    position: relative
    right: -20px


button.mat-mdc-fab.mat-accent[disabled],
button.mat-mdc-fab.mat-primary[disabled],
button.mat-mdc-fab.mat-warn[disabled],
button.mat-mdc-flat-button.mat-accent[disabled],
button.mat-mdc-flat-button.mat-primary[disabled],
button.mat-mdc-flat-button.mat-warn[disabled],
button.mat-mdc-mini-fab.mat-accent[disabled],
button.mat-mdc-mini-fab.mat-primary[disabled],
button.mat-mdc-mini-fab.mat-warn[disabled],
button.mat-mdc-raised-button.mat-accent[disabled],
button.mat-mdc-raised-button.mat-primary[disabled],
button.mat-mdc-raised-button.mat-warn[disabled]
  color: #00000042
  text-transform: uppercase

button.mat-mdc-fab.mat-accent,
button.mat-mdc-fab.mat-primary,
button.mat-mdc-fab.mat-warn,
button.mat-mdc-flat-button.mat-accent,
button.mat-mdc-flat-button.mat-primary,
button.mat-mdc-flat-button.mat-warn,
button.mat-mdc-mini-fab.mat-accent,
button.mat-mdc-mini-fab.mat-primary,
button.mat-mdc-mini-fab.mat-warn,
button.mat-mdc-raised-button.mat-accent,
button.mat-mdc-raised-button.mat-primary,
button.mat-mdc-raised-button.mat-warn
  color: #fff
  text-transform: uppercase

button.mat-mdc-button
  text-transform: uppercase

.mat-toolbar.mat-primary
  background: #4caf50
  color: #fff

html .mat-card
  padding: 0
  margin: 8px

html .mat-card mat-card-content
  padding: 16px

.fixed-add-button.mdc-fab
  position: fixed
  right: 10%
  bottom: 7%

  mat-icon
    font-size: 24px

.mat-button-toggle-appearance-standard
  background: none

mat-button-toggle
  width: unset !important
  height: unset !important
  border-radius: 0 !important

  .mat-icon
    margin-left: 0 !important
    color: $primary-color

.mat-button-toggle-checked
  background-color: #e0e0e0 !important
  color: rgba(0, 0, 0, 0.54) !important

.identity
  color: $secondary-text-color
  font-size: 12px
  margin-left: 10px

mat-bottom-sheet-container
  .mat-dialog-actions
    margin: 0 !important

[bottom-sheet-actions]
  display: flex
  justify-content: flex-end

  [mat-button]
    text-transform: uppercase

  [mat-button] + [mat-button]
    margin-left: 10px


.ticket-print-container
  display: block
  position: absolute
  z-index: -1
  opacity: 0

.ellipsis
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.text-transform-capitalize
  text-transform: capitalize

#sentry-feedback
  left: 1rem
  bottom: 2rem
  @media (max-width: 768px)
    bottom: 0.5rem


@media print
  .global-print-process
    opacity: 1 !important
    z-index: 1000000 !important
    position: relative !important

  .global-print-process + .angular-app
    display: none

  .no-print, .no-print-out
    display: none

@media (max-width: 650px)
  .notifier__notification
    padding: 10px 15px !important
    display: flex !important
    max-width: calc(100% - 25px) !important

.opened
  z-index: 1001 !important

.opened-form
  z-index: 1002 !important

@media print
  #open-cashier-support,
  #open-promoter-support
    opacity: 0 !important

  iframe[id*="mapw"],
  iframe[id*="0x0j"]
    opacity: 0 !important

  #custom-promoter-overlay,
  #custom-cashier-overlay
    opacity: 0 !important

.create-valuation-template-dialog
  min-width: 400px

.create-entrance-template-dialog
  max-width: 600px !important

.simple-notification-wrapper.error-notification
  display: flex
  flex-direction: column

  .simple-notification.error
    background: #d9534f
    min-height: unset
    padding: 15px 20px

    div:first-child
      display: flex
      align-items: center
      justify-content: space-between

      .sn-title
        display: none

      .sn-content
        padding-right: 10px
        width: 100%
        overflow: hidden
        text-overflow: ellipsis

      .icon
        position: unset
        height: 20px
        width: 20px
        background: url('./assets/svg/icon-cross.svg')
        opacity: 0.5

        svg
          display: none


.pre-line-tooltip
  white-space: pre-line
  *
    text-align: start !important

@media (max-width: 1024px)
  .block-no-scroll
    height: calc(100% - 56px)


@media (max-width: 768px)
  .fixed-add-button.mdc-fab
    position: fixed
    right: 9%
    bottom: 1%

