html
  --mat-form-field-container-height: 45px
  --mat-form-field-container-vertical-padding: 10px

  --mat-dialog-container-max-width: 90vw
  --mat-dialog-container-small-max-width: 100vw
  --mat-dialog-with-actions-content-padding: 0

  @media(max-width: 500px)
    --mat-table-header-headline-size: 12px
    --mat-table-row-item-label-text-size: 12px
    --mat-table-footer-supporting-text-size: 12px
    --mat-card-title-text-line-height: 22px

::ng-deep.mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix
  margin: 0 8px

::ng-deep.mat-mdc-tab-body-content
  overflow: hidden !important

::ng-deep mat-tab-header.mat-mdc-tab-header
  position: sticky
  top: 0
  background: #fff
  z-index: 10
